import moment from "moment";
import PartnerInfo from "../../../../components/partner-info";
import { TApplicationViewDetails } from "../../../../types";
import Comments from "../Review-comments/Comments";
import TransactionChart from "./TransactionChart";
import EmptyProfile from "../../../../assets/images/profile.jpg";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import { getLoanPartyType } from "../../../../helpers/getText";

const BenificiaryDetails: React.FC<{ details: TApplicationViewDetails }> = ({
  details,
}) => {
  return (
    <>
      <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
        <span className="text-sm font-semibold">
          CLICK Beneficiary Risk & Fraud Assessments
        </span>
      </div>
      <div className="bg-white px-3 py-4 border rounded-b-md">
        <div
          className="grow-[2.5] basis-0 flex justify-between"
          style={{ flexBasis: "4rem", width: "100%" }}
        >
          <div
            className="grow-[2] basis-0 flex flex-col"
            style={{ flexBasis: "8rem", width: "60%" }}
          >
            <PartnerInfo
              profilePic={details?.profilePic || EmptyProfile}
              name={details?.partyName || ""}
              shortName={details?.shortCode || ""}
              partnerType={getLoanPartyType(details?.partyType)}
              partnerId={details?.partyId}
              partnerMobile={details?.partyMobile || ""}
              partyName={details?.addressLine}
              fromLoansParty={true}
            />
          </div>
          {
            details.loanType == 'TRADE ADVANCE' &&
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "10%" }}
            >
              <span className="text-sm">CLICK On-Board Date</span>
              <span className="font-semibold text-sm">
                {moment(details?.partyObdate).format("DD-MMM-YYYY") || "-"}
              </span>
            </div>
          }
          {
            details.loanType == 'TRADE ADVANCE' &&
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "10%" }}
            >
              <span className="text-sm">CLICK Opening Balance</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(details?.partyOb) || "-"}
              </span>
            </div>
          }
          {
            details.loanType == 'TRADE ADVANCE' &&
            <div
              className="grow-[2.5] basis-0 flex px-2 flex-col"
              style={{ width: "10%" }}
            >
              <span className="text-sm">Created On</span>
              <span className="font-semibold text-sm">
                {moment(details?.partyCreatedOn).format("DD-MMM-YY | hh:mm:A") ||
                  "-"}
              </span>
            </div>
          }

          <div
            className="grow-[2.5] basis-0 flex px-2 flex-col"
            style={{ width: "10%" }}
          >
            <span className="text-sm">CLICK Vintage</span>
            <span className="font-semibold text-sm">
              {details?.vintage} Months
            </span>
          </div>
        </div>
        <div className="border-b px-2 py-2"></div>

        <div className="flex">
          <div className="flex border-r pt-2" style={{ width: "40%" }}>
            <div
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Total Bills</span>
              <span className="font-semibold text-sm">
                {details?.totalBills}
              </span>
            </div>
            <span
              className="grow-[2] basis-0 px-2 flex flex-col border-r"
              style={{ width: "20%" }}
            >
              {" "}
              <span className="text-sm">Total Business(Bill Value)</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(details?.totalBillAmt) || "-"}
              </span>
            </span>
          </div>
          <div className="flex pt-2" style={{ width: "40%" }}>
            {/* <div
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">First Bill</span>
              <span className="font-semibold text-sm">
                {moment(details?.firstBillDate).format("DD-MMM-YYYY") || "-"}{" "}
              </span>
            </div> */}
            <span
              className={`grow-[2] basis-0 px-2 flex flex-col ${details.loanType == 'TRADE ADVANCE' ? 'border-r' : ''}`}
              style={{ width: "20%" }}
            >
              {" "}
              <span className="text-sm">First Bill Create Date</span>
              <span className="font-semibold text-sm">
                {" "}
                {moment(details?.firstBillDate).format("DD-MMM-YYYY") ||
                  "-"}{" "}
              </span>
            </span>
          </div>
          {
            details.loanType == 'TRADE ADVANCE' &&
            <div className="flex pt-2" style={{ width: "20%" }}>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Other Partners</span>
                <span className="font-semibold text-sm">
                  {details?.otherPartners}
                </span>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="bg-light-primary px-3 py-2">
        <span className="text-sm font-semibold">Transaction Details</span>
      </div>

      <div
        className="text-sm py-4 px-2 cursor-pointer items-center bg-white"
        style={{ width: "100%" }}
      >
        <span className="text-sm font-semibold px-2">
          Bills Created over the Past 12 Months
        </span>

        <div className="py-4 px-2 br-5">
          <TransactionChart />
        </div>

        {(details?.loanAppStatus == "APPROVED" ||
          details?.loanAppStatus === "DISBURSED") && (
            <div className="">
              <Comments data={details} type="VERIFIED" />
            </div>
          )}
      </div>
    </>
  );
};
export default BenificiaryDetails;
