import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import {
  TBeneficiaryDetails,
  TCashKycDoc,
  TPayoutDetails,
} from "../types/index";
import { TRootState } from "../store";
import { updateBeneficiaryDetails } from "../reducers/borrowerSlice";
import { setSelectedPayout } from "../reducers/tradeSlice";

const useBeneficiaryDetailsById = ({ appId, loanAccNo, }: { appId?: number, loanAccNo?: number, }) => {
  const { handleGetCall } = useAxiosClient();
  const dispatch = useDispatch();
  const { adminId, role, InitBorrowerDetail } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitBorrowerDetail: state.borrowers.beneficiaryDetails,
    })
  );
  const [beneficiaryDetails, setBeneficiaryDetails] =
    useState<TBeneficiaryDetails | null>(InitBorrowerDetail);
  const [confirmAccountNum, setConfirmAccountNum] = useState(
    InitBorrowerDetail?.accNum
  );

  const getBeneficiaryDetail = () => {
    handleGetCall<TBeneficiaryDetails>(
      `admin/cash/beneficiaries/adminId/${adminId}/appId/${appId}?loanAccNo=${loanAccNo}`
    ).then(({ data }) => {
      if (data) {
        let kycDocs: TCashKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docTypes: "pdf" | "image" = image.docUrl.includes(".pdf")
              ? "pdf"
              : "image";
            kycDocs.push({ ...image, type: docTypes, status: 2 });
          }
        }
        let accounts: TPayoutDetails[] = [];
        if (data.payAcc != null && data.payAcc.length > 0) {
          for (let account of data.payAcc) {
            accounts.push({
              ...account,
              actType: "Bank Account",
              contactNumber: account.mobile,
              emailAddress: account.email,
            });
          }
        }
        setBeneficiaryDetails({
          ...data,
          kycDocs: kycDocs,
          payAcc: accounts,
        });
        setConfirmAccountNum(data.accNum);
        dispatch(
          updateBeneficiaryDetails({
            beneficiaryDetails: {
              ...data,
              kycDocs: kycDocs,
              payAcc: accounts,
            },
          })
        );
        if (data.payAcc && data.payAcc.length > 0) {
          dispatch(
            setSelectedPayout({
              ...data.payAcc[0],
              actType: "Bank Account",
              contactNumber: data.payAcc[0].mobile,
              emailAddress: data.payAcc[0].email,
              type: "BENIFICIARY",
            })
          );
        }
      } else {
        setBeneficiaryDetails(null);
      }
    });
  };

  return {
    beneficiaryDetails,
    getBeneficiaryDetail,
    role,
    confirmAccountNum,
    setConfirmAccountNum,
  };
};
export default useBeneficiaryDetailsById;
