import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import {
  TBorrowerDetails,
  TBorrowers,
  TCashKycDoc,
  TPayoutDetails,
  TTrader,
  TTraderKycDoc,
} from "../types/index";
import { TRootState } from "../store";
import { updateBorrowerDetails } from "../reducers/borrowerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setSelectedPayout } from "../reducers/tradeSlice";

const useBorrowerDetailsById = ({
  appId,
  type,
  fromBorrowerPAge,
}: {
  appId?: number;
  type?: string;
  fromBorrowerPAge?: boolean;
}) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminId, role, InitBorrowerDetail, platformType } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitBorrowerDetail: state.borrowers.borrowerDetails,
      platformType: state.borrowers.platformType,
    })
  );
  const [confirmAccountNum, setConfirmAccountNum] = useState(
    InitBorrowerDetail?.accNum
  );
  const [onRefresh, setOnRefresh] = useState(false);
  const [borrowerDetails, setBorrowerDetails] =
    useState<TBorrowerDetails | null>(InitBorrowerDetail);
  console.log(type, "type");
  const getBorrowerDetail = () => {
    handleGetCall<TBorrowerDetails>(
      `admin/cash/borrowers/adminId/${adminId}/appId/${appId}?type=${type}`
    ).then(({ data }) => {
      if (data) {
        let partnersObj = null;
        let totalPartners = 0;
        let kycDocs: TCashKycDoc[] = [];
        let bankDocs: TCashKycDoc[] = [];
        let itrDocs: TCashKycDoc[] = [];
        let licenceDocs: TCashKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docTypes: "pdf" | "image" = image.docUrl.includes(".pdf")
              ? "pdf"
              : "image";
            kycDocs.push({ ...image, type: docTypes, status: 2 });
            // if (image.docType === "PAN_CARD") {
            //   panDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "BANK_STATEMENT") {
            //   bankDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "ITR") {
            //   itrDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "LICENCE") {
            //   licenceDocs.push({ ...image, type: docTypes, status: 1 });
            // }
          }
        }
        let accounts: TPayoutDetails[] = [];
        if (data.cashProfile?.payAcc != null && data.cashProfile?.payAcc.length > 0) {
          for (let account of data.cashProfile.payAcc) {
            accounts.push({
              ...account,
              actType: "Bank Account",
              contactNumber: account.mobile,
              emailAddress: account.email,
            });
          }
        }
        if (data?.partnerDetails?.length > 0) {
          const sellers = data?.partnerDetails
            .filter(
              (item) =>
                item.partyType === "FARMER" || item.partyType === "TRADER"
            )
            .reduce((sum, item) => sum + item.total, 0);

          partnersObj = data.partnerDetails.reduce((obj: any, item) => {
            if (item.partyType === "FARMER" || item.partyType === "TRADER") {
              obj["seller"] = sellers;
            } else {
              obj[item.partyType.toLowerCase()] = item.total;
            }
            return obj;
          }, {});
          totalPartners = data.partnerDetails.reduce(
            (sum, item) => sum + item.total,
            0
          );
        }

        setBorrowerDetails({
          ...data,
          totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
          totalBills: data?.buyBills + data?.sellBills,
          partnerObjects: partnersObj || null,
          totalPartners: totalPartners,
          kycDocs: kycDocs,
          tenureString: data.tenure ? data.tenure.toString() : "",
          cashProfile: {
            ...data.cashProfile,
            payAcc: accounts,
          },
          renewDate: data.renewDate,
        });
        setConfirmAccountNum(data.accNum);
        dispatch(
          updateBorrowerDetails({
            borrowerDetails: {
              ...data,
              totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
              totalBills: data?.buyBills + data?.sellBills,
              partnerObjects: partnersObj || null,
              totalPartners: totalPartners,
              kycDocs: kycDocs,
              tenureString: data.tenure ? data.tenure.toString() : "",
              cashProfile: {
                ...data.cashProfile,
                payAcc: accounts,
              },
            },
          })
        );
        if (data?.cashProfile?.payAcc && data?.cashProfile?.payAcc.length > 0 && fromBorrowerPAge) {
          dispatch(
            setSelectedPayout({
              ...data?.cashProfile?.payAcc[0],
              actType: "Bank Account",
              contactNumber: data?.cashProfile?.payAcc[0].mobile,
              emailAddress: data?.cashProfile?.payAcc[0].email,
              type: "BORROWER",
            })
          );
        }
      } else {
        setBorrowerDetails(null);
      }
    });
  };
  const analyseEvent = () => {
    let images = [];
    if (borrowerDetails?.kycDocs && borrowerDetails?.kycDocs.length > 0) {
      for (let file of borrowerDetails?.kycDocs) {
        if (file.docType == "BANK_STATEMENT") {
          images.push({
            docId: file.id,
            fileUrl: file.docUrl,
            // password: "",
          });
        }
      }
    }
    //dev-api.onoark.com/v1/admin/bank-stmt/analyze
    handlePostCall({
      URL: `admin/intgr/bank-stmt/analyze`,
      apiParams: {
        files: images,
        pfId: borrowerDetails?.loanAccNo,
        platform: "CASH",
      },
    })
      .then(({ data, status }) => {
        toast.success(status.message);
        getBorrowerDetail();
        navigate(`/borrower-detail/${borrowerDetails?.appId}`);
      });
  };

  return {
    borrowerDetails,
    getBorrowerDetail,
    role,
    analyseEvent,
    platformType,
    confirmAccountNum,
    setConfirmAccountNum
  };
};
export default useBorrowerDetailsById;
