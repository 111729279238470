import React, { useEffect, useMemo, useState } from "react";
import PartnerInfo from "../../../../components/partner-info";
import classes from "./style.module.scss";
import loan from "../../../../assets/images/loan.svg";
import loanDEtailsY from "../../../../assets/images/loanDEtailsW.svg";
import tick from "../../../../assets/images/blueTick.svg";
import cash from "../../../../assets/images/cash_history.svg";
import cashWhite from "../../../../assets/images/cashW.svg";
import approve from "../../../../assets/images/approveTick.svg";
import { Button, PanelSlider } from "../../../../components/ui";
import DocumentPreview from "../../../../components/commons/document-preview";
import timer from "../../../../assets/images/timer-img.png";
import rejectExclamatory from "../../../../assets/images/rejectExclamatory.svg";
import { useNavigate, useParams } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import FinalReview from "../Final-Review/FinalReview";
import Modal from "../../../../components/ui/modal";
import ModalHeader from "../../../../components/ui/modal/modal-header";
import { useDispatch, useSelector } from "react-redux";
import AdvDisbursalModal from "../Advance-Disbursal/AdvDisbursalModal";
import DisbursementDetails from "../Disburse-Details/DisbursementDetails";
import RepaymentHistory from "../Repayment-History/RepaymentHistory";
import LoanManagerInfo from "../Loan-Manager-info/LoanManagerInfo";
import { ArrowBack } from "@mui/icons-material";
import useApplicationViewById from "./useApplicationViewById";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../../../helpers/get-currency-number";
import moment from "moment";
import LoanDetails from "../LoanBankDetails.tsx/LoanDetails";
import CashHistory from "../Cash-History/CashHistory";
import BenificiaryDetails from "../Risk-Fraud/BenificiaryDetails";
import { TRootState } from "../../../../store";
import useAllLoanApplications from "../useAllApplications";
import { toast } from "react-toastify";
import close from "../../../../assets/images/roundClose.svg";
import MemoizedGaugeChart from "../../../loans/Applications/application-view/MemoizedGaugeChart";
import { getLoanPartyType } from "../../../../helpers/getText";
import reverted from "../../../../assets/images/revert.svg";

const ApplicationView = () => {
  const [documentsSubmitted, setDocumentsSubmitted] = useState(false); // Tracks if documents are submitted
  const [ratingGiven, setRatingGiven] = useState(false); // Tracks if rating is given
  const navigate = useNavigate(); // To navigate to the KYC documents page
  const [loansTab, setLoansTab] = useState("Loan Disbursed");
  const params = useParams();
  const {
    applicationViewDetails,
    getApplicationDetail,
    role,
    goToNextStepEvent,
    adminInfo,
    setApplicationViewDetails,
    activeStep,
    setActiveStep,
    rejectStepEvent,
    activeTab,
    setActiveTab,
    closeLoan,
  } = useApplicationViewById({
    applicationId: parseInt(params.applicationId || "0"),
  });
  const [openDisbursal, setOpenDisbursal] = useState(false);
  const { loanApplicationArray } = useAllLoanApplications({});

  useEffect(() => {
    getApplicationDetail();
  }, [params]);

  const getLastProgStatus = (status: string) => {
    return (
      applicationViewDetails?.loanPrgs[
        applicationViewDetails?.loanPrgs.length - 1
      ]?.progStatus == status
    );
  };

  const getLastBeforeProgStatus = (status: string) => {
    return (
      applicationViewDetails?.loanPrgs[
        applicationViewDetails?.loanPrgs.length - 2
      ]?.progStatus == status
    );
  };

  const isRejectedStep = (status: string) => {
    return (
      applicationViewDetails?.loanAppStatus == "REJECTED" &&
      getLastBeforeProgStatus(status)
    );
  };
  useEffect(() => {
    if (getLastProgStatus("PENDING")) {
      setActiveStep(1);
      setActiveTab("Loan Details");
    }
    if (
      applicationViewDetails?.loanPrgs[
        applicationViewDetails?.loanPrgs.length - 1
      ]?.progStatus == "REVIEWED"
    ) {
      setActiveStep(2);
      setActiveTab("Risk & Fraud Assessment");
    }
    if (
      applicationViewDetails?.loanPrgs[
        applicationViewDetails?.loanPrgs.length - 1
      ]?.progStatus == "VERIFIED"
    ) {
      setActiveStep(3);
      setActiveTab("CASH History");
    }
    if (applicationViewDetails?.loanAppStatus == "APPROVED") {
      setActiveStep(4);
      setActiveTab("Final Review and Approvals");
    }
    if (applicationViewDetails?.loanAppStatus == "DISBURSED") {
      setActiveStep(5);
    }
  }, [applicationViewDetails?.loanAppStatus]);

  const pendingStep = () => {
    if (
      applicationViewDetails?.loanAppStatus == "REJECTED" &&
      getLastBeforeProgStatus("PENDING")
    ) {
      return "bg-light-red border-red color-red";
    } else if (activeTab === "Loan Details" || activeStep === 1) {
      return "bg-primary color-yellow";
    } else if (
      activeStep > 1 ||
      applicationViewDetails?.loanAppStatus == "CLOSED"
    ) {
      return "bg-light-primary border-primary";
    }

    return "";
  };

  const reviewedStep = () => {
    if (
      applicationViewDetails?.loanAppStatus == "REJECTED" &&
      getLastBeforeProgStatus("REVIEWED")
    ) {
      return "bg-light-red border-red color-red";
    } else if (activeTab === "Risk & Fraud Assessment" || activeStep == 2) {
      return "bg-primary color-yellow";
    } else if (
      activeStep > 2 ||
      applicationViewDetails?.loanAppStatus == "CLOSED"
    ) {
      return "bg-light-primary border-primary";
    }

    return "";
  };
  const verifiedStep = () => {
    if (
      applicationViewDetails?.loanAppStatus == "REJECTED" &&
      getLastBeforeProgStatus("VERIFIED")
    ) {
      return "bg-light-red border-red color-red";
    } else if (activeTab === "CASH History" || activeStep == 3) {
      return "bg-primary color-yellow";
    } else if (
      activeStep > 3 ||
      applicationViewDetails?.loanAppStatus == "CLOSED"
    ) {
      return "bg-light-primary border-primary";
    }
    return "";
  };

  const approvedStep = () => {
    if (
      applicationViewDetails?.loanAppStatus == "REJECTED" &&
      getLastBeforeProgStatus("APPROVED")
    ) {
      return "bg-light-red border-red color-red";
    } else if (
      activeTab === "Final Review and Approvals" ||
      applicationViewDetails?.loanAppStatus === "APPROVED"
    ) {
      return "bg-primary color-yellow";
    } else if (
      (applicationViewDetails?.loanAppStatus === "APPROVED" && activeStep > 4) ||
      applicationViewDetails?.loanAppStatus == "CLOSED"
    ) {
      return "bg-light-primary border-primary";
    }

    return "";
  };

  const onoScoreBorrower = useMemo(() => {
    return applicationViewDetails?.bwrScore
      ? applicationViewDetails.bwrScore / 10
      : 0;
  }, [applicationViewDetails?.bwrScore]);

  const onoScoreBeneficiary = useMemo(() => {
    return applicationViewDetails?.partyScore
      ? applicationViewDetails.partyScore / 10
      : 0;
  }, [applicationViewDetails?.partyScore]);

  return (
    <div className="">
      <div className="border overflow-hidden bg-white mb-4 px-4">
        <div className="flex border-b text-sm py-4 cursor-pointer items-center">
          <div
            className="grow-[2.5] basis-0 flex items-center gap-2 px-2"
            style={{ flexBasis: "4rem", width: "30%" }}
            onClick={() => {
              navigate("/loans");
            }}
          >
            <ArrowBack />
            <PartnerInfo
              profilePic=""
              name={applicationViewDetails?.bwrName || ""}
              shortName={""}
              partnerType={"CASH ID "}
              partnerId={applicationViewDetails?.loanAccNo}
              partnerMobile={
                applicationViewDetails?.bwrMobile
                  ? applicationViewDetails?.bwrMobile
                  : ""
              }
              partyName={""}
              fromLoansParty={true}
            />
          </div>
          <div className="grow-[2.5] basis-0 flex flex-col px-4 border-r">
            <MemoizedGaugeChart
              id="gauge-chart-beneficiary"
              percent={onoScoreBorrower}
            />
            <span
              className={`font-semibold text-sm ${
                applicationViewDetails?.bwrScore! <= 3
                  ? "color-red"
                  : applicationViewDetails?.bwrScore! <= 7
                  ? "color-orange"
                  : "color-primary"
              }`}
            >
              {applicationViewDetails?.bwrScore} / 10{" "}
              {applicationViewDetails?.bwrScore! <= 3
                ? "Poor"
                : applicationViewDetails?.bwrScore! <= 7
                ? "Average"
                : "Good"}
            </span>
            {/* <Button
              variant="outlined"
              className="ml-auto gap-1"
              size="small"
              onClick={() => {
                navigate("");
              }}
            >
              Verify
              <br /> KYC Documents
            </Button> */}
          </div>
          <div className="flex" style={{ width: "65%" }}>
            <span
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              {" "}
              <span className="text-sm">Cash Limit</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.eligibleLimit
                ) || "-"}
              </span>
            </span>
            <div
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Cash Utilized</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.utilisedLimit
                ) || "-"}
              </span>
            </div>
            <span
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              {" "}
              <span className="text-sm">Cash Available</span>
              <span className="font-semibold text-sm">
                {" "}
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.availableLimit
                ) || "-"}
              </span>
            </span>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Overdue Amount</span>
              <span className="font-semibold text-sm color-red">
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.overDueAmt
                ) || "-"}
              </span>
            </div>
          </div>
          {/* <span className="grow-[0.5] basis-0 px-2"></span> */}
        </div>
        <div className="flex border-b text-sm py-4 cursor-pointer items-center">
          <div
            className="grow-[2.5] basis-0 flex justify-between px-4 border-r"
            style={{ flexBasis: "4rem", width: "40%" }}
          >
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Total Loans</span>
              <span className="font-semibold text-sm ">
                {applicationViewDetails?.totalLoans || 0}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Active Loans</span>
              <span className="font-semibold text-sm">
                {applicationViewDetails?.activeLoans || 0}
              </span>
            </div>
            <div
              className="grow-[2] basis-0 flex px-2 flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Onboarded On</span>
              <span className="font-semibold text-sm">
                {moment(applicationViewDetails?.onboardedOn).format(
                  "DD-MMM-YYYY"
                )}
              </span>
            </div>
          </div>
          <div className="flex" style={{ width: "60%" }}>
            <div
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Total Disbursement</span>
              <span className="font-semibold text-sm">
                {getCurrencyNumberWithSymbol(applicationViewDetails?.disbAmt) ||
                  "-"}
              </span>
            </div>
            <span
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              {" "}
              <span className="text-sm">Total Recovery</span>
              <span className="font-semibold text-sm">
                {" "}
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.recoveredAmt
                ) || "-"}
              </span>
            </span>
            <div
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              <span className="text-sm">Total Overdue</span>
              <span className="font-semibold text-sm">
                {" "}
                {getCurrencyNumberWithSymbol(
                  applicationViewDetails?.overDueAmt
                ) || "-"}
              </span>
            </div>
            <span
              className="grow-[2] basis-0 px-2 flex flex-col"
              style={{ width: "20%" }}
            >
              {/* {" "}
            <span className="text-sm">Cash Available</span>
            <span className="font-semibold text-sm">₹20,00,000</span> */}
            </span>
          </div>
          {/* <span className="grow-[0.5] basis-0 px-2"></span> */}
        </div>
        <div className="flex py-4 gap-2">
          <div
            className="flex bg-light-primary br-10 items-center"
            style={{ width: "100%" }}
          >
            <span className="font-medium px-4">DPD</span>
            {applicationViewDetails?.delayDetails.map((item, i) => (
              <div className="grow-[2] py-4 px-4" style={{ width: "20%" }}>
                <div className="flex items-center gap-3">
                  <span className="font-medium">
                    {" "}
                    {item.pmtDays != "NPA" ? "" : item.pmtDays}
                  </span>
                  <div className="flex flex-col">
                    <span className="text-xs color-text-primary font-medium">
                      {item.pmtDays != "NPA"
                        ? item.pmtDays
                        : item.loans + " Loans"}
                    </span>
                    <span className="text-sm font-medium">
                      <span>
                        {item.pmtDays != "NPA"
                          ? (item.loans || 0) + " Loans"
                          : ""}
                      </span>
                      <span>
                        ({getCurrencyNumberWithSymbol(item.amount) || 0})
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-light-primary px-4 py-2 mt-3 rounded-t-md">
          <span className="text-sm font-semibold">
            Beneficiary Risk Parameters
          </span>
        </div>
      </div>
      <div className="border rounded-b-md px-4 bg-white mx-4 mb">
        <div className="flex border-b text-sm py-4 cursor-pointer items-center">
          {/* <span
            className="grow-[1.5] basis-0 px-2 flex flex-col"
            style={{ width: "10%" }}
          >
            {" "}
            <span className="text-sm">Loan ID</span>
            <span className="text-sm color-blue font-semibold">
              {applicationViewDetails?.disbId}{" "}
            </span>
            <span className="text-sm">
              {moment(applicationViewDetails?.disbDate).format("DD-MMM-YYYY") ||
                "-"}
            </span>
          </span> */}
          <div
            className="grow-[2.5] basis-0 flex flex-col px-4"
            style={{ flexBasis: "4rem", width: "35%" }}
          >
            <PartnerInfo
              profilePic={applicationViewDetails?.profilePic || ""}
              name={applicationViewDetails?.partyName || ""}
              shortName={applicationViewDetails?.shortCode}
              partnerType={getLoanPartyType(applicationViewDetails?.partyType)}
              partnerId={applicationViewDetails?.partyId}
              partnerMobile={applicationViewDetails?.partyMobile || ""}
              partyName={applicationViewDetails?.addressLine}
              fromLoansParty={true}
            />
          </div>

          <div
            className="grow-[2] basis-0 flex flex-col px-4 border-r"
            style={{ width: "20%" }}
          >
            {/* <Button
              variant="outlined"
              className="ml-auto gap-1"
              size="small"
              onClick={() => { }}
            >
              verify
              <br /> KYC Documents
            </Button> */}

            <MemoizedGaugeChart
              id="gauge-chart-borrower"
              percent={onoScoreBeneficiary}
            />
            <span
              className={`font-semibold text-sm ${
                applicationViewDetails?.partyScore! <= 3
                  ? "color-red"
                  : applicationViewDetails?.partyScore! <= 7
                  ? "color-orange"
                  : "color-primary"
              }`}
            >
              {applicationViewDetails?.partyScore!} / 10{" "}
              {applicationViewDetails?.partyScore! <= 3
                ? "Poor"
                : applicationViewDetails?.partyScore! <= 7
                ? "Average"
                : "Good"}
            </span>
          </div>

          <div
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm">Age</span>
            <span className="font-semibold text-sm ">{"-"}</span>
          </div>
          <span
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            {" "}
            <span className="text-sm">Cash Limit</span>
            <span className="font-semibold text-sm">
              {applicationViewDetails?.loanType == "WORKING CAPITAL"
                ? getCurrencyNumberWithSymbol(
                    applicationViewDetails?.eligibleLimit
                  ) || "-"
                : "-"}
            </span>
          </span>

          <span
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            {" "}
            <span className="text-sm">Cash Available</span>
            <span className="font-semibold text-sm">
              {" "}
              {applicationViewDetails?.loanType == "WORKING CAPITAL"
                ? getCurrencyNumberWithSymbol(
                    applicationViewDetails?.availableLimit
                  ) || "-"
                : "-"}
            </span>
          </span>
          <div
            className="grow-[2] basis-0 flex px-2 flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm">Overdue</span>
            <span className="font-semibold text-sm color-red">
              {" "}
              {getCurrencyNumberWithSymbol(
                applicationViewDetails?.overDueAmt
              ) || "-"}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center py-4">
          <div className="relative progress_bar">
            {applicationViewDetails?.loanAppStatus != "DISBURSED" &&
            applicationViewDetails?.loanAppStatus != "CLOSED" ? (
              <ul
                className={
                  "flex items-center py-3 gap-6 font-medium " +
                  classes.border_style
                }
              >
                <li
                  className={`relative flex gap-2 bg-white border br-10 px-2 py-2 text-sm cursor-pointer items-center ${pendingStep()}`}
                  onClick={() => {
                    if (activeStep >= 1) {
                      setActiveTab("Loan Details");
                      return;
                    }
                  }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full 
                      ${
                        isRejectedStep("PENDING")
                          ? "bg-red"
                          : activeStep > 1
                          ? "bg-primary"
                          : activeTab === "Loan Details" || activeStep === 1
                          ? "bg-yellow"
                          : "bg-gray"
                      }`}
                  >
                    <img
                      src={
                        isRejectedStep("PENDING") ||
                        (activeStep > 1 && activeTab != "Loan Details")
                          ? loanDEtailsY
                          : loan
                      }
                    />
                  </div>
                  <span className="py-1">
                    Loan <br></br> Details
                  </span>
                  {isRejectedStep("PENDING") ? (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                      <img src={rejectExclamatory} alt="rejected" />
                    </div>
                  ) : (
                    (activeStep > 1 ||
                      applicationViewDetails?.loanAppStatus == "CLOSED") && (
                      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                        <img src={tick} alt="Completed" className="w-4 h-4" />
                      </div>
                    )
                  )}
                </li>
                <li
                  className={`relative flex gap-2 bg-white border br-10 px-2 py-2 text-sm items-center cursor-pointer 
                    ${reviewedStep()}`}
                  onClick={() => {
                    if (activeStep >= 2) {
                      setActiveTab("Risk & Fraud Assessment");
                      return;
                    }
                  }}
                  style={{ height: "65px" }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full 
                      ${
                        isRejectedStep("REVIEWED")
                          ? "bg-red"
                          : activeStep > 2
                          ? "bg-primary"
                          : activeTab === "Risk & Fraud Assessment" ||
                            activeStep === 2
                          ? "bg-yellow"
                          : "bg-gray"
                      }`}
                  >
                    {/* <img src={loan} /> */}
                    <img
                      src={
                        isRejectedStep("REVIEWED") ||
                        (activeStep > 2 &&
                          activeTab != "Risk & Fraud Assessment")
                          ? loanDEtailsY
                          : loan
                      }
                    />
                  </div>

                  <span>
                    Risk & Fraud <br /> Assessment
                  </span>

                  {isRejectedStep("REVIEWED") ? (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                      <img src={rejectExclamatory} alt="rejected" />
                    </div>
                  ) : (
                    (activeStep > 2 ||
                      applicationViewDetails?.loanAppStatus == "CLOSED") && (
                      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                        <img src={tick} alt="Completed" className="w-4 h-4" />
                      </div>
                    )
                  )}
                </li>
                <li
                  className={`relative bg-white flex gap-2 border br-10 px-2 py-2 text-sm items-center cursor-pointer 
                    ${verifiedStep()}`}
                  onClick={() => {
                    if (activeStep >= 3) {
                      setActiveTab("CASH History");
                      return;
                    }
                  }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full 
                    ${
                      isRejectedStep("VERIFIED")
                        ? "bg-red"
                        : activeStep > 3
                        ? "bg-primary"
                        : activeTab === "CASH History" || activeStep === 3
                        ? "bg-yellow"
                        : "bg-gray"
                    }`}
                  >
                    <img
                      src={
                        isRejectedStep("VERIFIED") ||
                        (activeStep > 3 && activeTab != "CASH History")
                          ? cashWhite
                          : cash
                      }
                    />
                  </div>
                  <span className="py-1">
                    CASH <br /> History
                  </span>
                  {isRejectedStep("VERIFIED") ? (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                      <img src={rejectExclamatory} alt="rejected" />
                    </div>
                  ) : (
                    (activeStep > 3 ||
                      applicationViewDetails?.loanAppStatus == "CLOSED") && (
                      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                        <img src={tick} alt="Completed" className="w-4 h-4" />
                      </div>
                    )
                  )}
                </li>

                <li
                  className={`relative bg-white flex gap-2 border br-10 px-2 py-2 text-sm items-center cursor-pointer 
                    ${approvedStep()}`}
                  onClick={() => {
                    if (activeStep >= 4) {
                      setActiveTab("Final Review and Approvals");
                      return;
                    }
                  }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full 
                      ${
                        isRejectedStep("APPROVED")
                          ? "bg-red"
                          : activeStep > 4
                          ? "bg-primary"
                          : activeTab === "Final Review and Approvals" ||
                            activeStep === 4
                          ? "bg-yellow"
                          : "bg-gray"
                      }`}
                  >
                    <img
                      src={
                        isRejectedStep("APPROVED") ||
                        (activeStep > 4 &&
                          activeTab != "Final Review and Approvals")
                          ? cashWhite
                          : cash
                      }
                    />
                  </div>
                  <span className="py-1">
                    Final Review and <br /> Approvals
                  </span>

                  {isRejectedStep("APPROVED") ? (
                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                      <img src={rejectExclamatory} alt="rejected" />
                    </div>
                  ) : (
                    (activeStep > 4 ||
                      applicationViewDetails?.loanAppStatus == "CLOSED") && (
                      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                        <img src={tick} alt="Completed" className="w-4 h-4" />
                      </div>
                    )
                  )}
                </li>
              </ul>
            ) : (
              <ul
                className={
                  "flex py-3 gap-6 font-medium " + classes.border_style
                }
              >
                <li
                  className={`cursor-pointer flex gap-2 border br-10 items-center px-2 py-2 text-sm ${
                    loansTab === "Loan Summary"
                      ? "bg-primary border-primary color-yellow "
                      : "bg-light-primary color-green"
                  }`}
                  onClick={() => {
                    setLoansTab("Loan Summary");
                  }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      loansTab === "Loan Summary" ? "bg-yellow" : "bg-gray"
                    }`}
                  >
                    <img src={loan} />
                  </div>
                  <span>Loan Summary</span>
                </li>
                <li
                  className={`cursor-pointer flex gap-2 border br-10 items-center px-2 py-2 text-sm ${
                    loansTab === "Loan Disbursed"
                      ? "bg-primary border-primary color-yellow"
                      : "bg-light-primary color-green"
                  }`}
                  onClick={() => {
                    setLoansTab("Loan Disbursed");
                  }}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      loansTab === "Loan Disbursed" ? "bg-yellow" : "bg-gray"
                    }`}
                  >
                    <img src={cash} />
                  </div>
                  <span>Loan Disbursed</span>
                </li>
              </ul>
            )}
          </div>

          <div className="flex gap-3">
            {applicationViewDetails?.payoutRes?.status == "REVERTED" && (
              <div>
                <Button
                  onClick={() => {
                    setOpenDisbursal(true);
                  }}
                  styles={{ height: "68px" }}
                >
                  Disburse
                </Button>
              </div>
            )}
            <>
              {applicationViewDetails?.loanAppStatus != "DISBURSED" ? (
                <div
                  // className="flex flex-col font-medium"
                  // variant={
                  //   applicationViewDetails?.loanAppStatus == "REJECTED"
                  //     ? "lightRed"
                  //     : applicationViewDetails?.loanAppStatus == "CLOSED"
                  //     ? "primaryLight"
                  //     : "lightOrange"
                  // }
                  className={`${classes.orderStatus} font-medium text-sm flex flex-col items-center`}
                  data-status={
                    applicationViewDetails?.payoutRes == null
                      ? applicationViewDetails?.loanAppStatus
                      : applicationViewDetails?.payoutRes?.status
                  }
                >
                  <span
                    style={{ color: "#000" }}
                    className="text-sm font-medium"
                  >
                    Application Status
                  </span>
                  <div className="flex py-1 gap-1">
                    <span>
                      <img
                        src={
                          applicationViewDetails?.loanAppStatus == "REJECTED" ||
                          applicationViewDetails?.payoutRes?.status ==
                            "FAILED" ||
                          applicationViewDetails?.payoutRes?.status ==
                            "REJECTED"
                            ? close
                            : applicationViewDetails?.loanAppStatus == "CLOSED"
                            ? approve
                            : timer
                        }
                        className="w-5"
                      />
                    </span>
                    <span
                    // className={`${
                    //   applicationViewDetails?.loanAppStatus == "REJECTED"
                    //     ? "color-red"
                    //     : applicationViewDetails?.loanAppStatus == "CLOSED"
                    //     ? "color-primary"
                    //     : "color-orange"
                    // } `}
                    >
                      {applicationViewDetails?.payoutRes == null || applicationViewDetails?.loanAppStatus != 'DISBURSED'
                        ? applicationViewDetails?.loanAppStatus
                        : applicationViewDetails?.payoutRes?.status}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  // className="flex flex-col font-medium"
                  // variant="primaryLight"
                  className={`${classes.orderStatus} font-medium text-sm flex flex-col items-center`}
                  data-status={
                    applicationViewDetails?.payoutRes == null
                      ? applicationViewDetails?.loanAppStatus
                      : applicationViewDetails?.payoutRes?.status
                  }
                >
                  <span
                    style={{ color: "#000" }}
                    className="text-sm font-medium"
                  >
                    Application Status
                  </span>
                  <div className="flex py-1 gap-1">
                    <span>
                      <img
                        src={
                          applicationViewDetails?.payoutRes == null
                            ? approve
                            : applicationViewDetails?.payoutRes?.status ==
                              "INITIATED"
                            ? timer
                            : applicationViewDetails?.payoutRes?.status ==
                              "REVERTED"
                            ? reverted
                            : approve
                        }
                        className="w-5"
                      />
                    </span>
                    <span>
                      {applicationViewDetails?.payoutRes == null
                        ? applicationViewDetails?.loanAppStatus
                        : applicationViewDetails?.payoutRes?.status}
                    </span>
                  </div>
                  <span className="text-sm color-black">
                    {applicationViewDetails?.payoutRes?.rejectedReason}
                  </span>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      <div className="flex gap-4 px-4">
        {applicationViewDetails?.loanAppStatus !== "DISBURSED" &&
          applicationViewDetails?.loanAppStatus !== "CLOSED" && (
            <div className="pb-4" style={{ width: "75%" }}>
              {(() => {
                if (
                  activeStep === 1 ||
                  activeTab === "Loan Details" ||
                  (getLastProgStatus("REJECTED") &&
                    getLastBeforeProgStatus("PENDING"))
                ) {
                  return <LoanDetails details={applicationViewDetails!} />;
                }
                if (
                  activeStep === 2 ||
                  activeTab === "Risk & Fraud Assessment" ||
                  (getLastProgStatus("REJECTED") &&
                    getLastBeforeProgStatus("REVIEWED"))
                ) {
                  return (
                    <BenificiaryDetails details={applicationViewDetails!} />
                  );
                }
                if (
                  activeStep === 3 ||
                  activeTab === "CASH History" ||
                  (getLastProgStatus("REJECTED") &&
                    getLastBeforeProgStatus("VERIFIED"))
                ) {
                  return <CashHistory details={applicationViewDetails!} />;
                }
                if (
                  activeStep === 4 ||
                  activeTab === "Final Review and Approvals" ||
                  (getLastProgStatus("REJECTED") &&
                    getLastBeforeProgStatus("APPROVED"))
                ) {
                  return (
                    <FinalReview finalLoanDetails={applicationViewDetails!} />
                  );
                }

                return null;
              })()}
            </div>
          )}
        <div className="" style={{ width: "25%" }}>
          {applicationViewDetails?.loanAppStatus != "DISBURSED" &&
            applicationViewDetails?.loanAppStatus != "CLOSED" && (
              <div>
                <LoanManagerInfo
                  info={adminInfo}
                  nextStepEvent={goToNextStepEvent}
                  setApplicationDetails={setApplicationViewDetails}
                  applicationData={applicationViewDetails}
                  activeStep={activeStep}
                  rejectStep={rejectStepEvent}
                  activeTab={activeTab}
                />
              </div>
            )}
        </div>
      </div>

      <div className="items-center" style={{ width: "100%" }}>
        {loansTab === "Loan Summary" &&
          (applicationViewDetails?.loanAppStatus === "DISBURSED" ||
            applicationViewDetails?.loanAppStatus === "CLOSED") && (
            <div className="px-4 flex">
              <div style={{ width: "70%" }}>
                <div className="">
                  <LoanDetails details={applicationViewDetails!} />
                </div>
                <div className="">
                  <BenificiaryDetails details={applicationViewDetails!} />
                </div>
                <div className="">
                  <CashHistory details={applicationViewDetails!} />
                </div>
              </div>
              <div style={{ width: "30%" }} className="ml-2">
                <LoanManagerInfo
                  info={adminInfo}
                  nextStepEvent={goToNextStepEvent}
                  setApplicationDetails={setApplicationViewDetails}
                  applicationData={applicationViewDetails}
                  activeStep={activeStep}
                  rejectStep={rejectStepEvent}
                />
              </div>
            </div>
          )}
      </div>
      <div className="items-center px-4 py-2" style={{ width: "100%" }}>
        {loansTab === "Loan Disbursed" &&
          (applicationViewDetails?.loanAppStatus === "DISBURSED" ||
            applicationViewDetails?.loanAppStatus === "CLOSED") && (
            <>
              <div className="flex">
                <div className="mr-2" style={{ width: "50%" }}>
                  <DisbursementDetails />
                </div>
                <div className="" style={{ width: "50%" }}>
                  <RepaymentHistory />
                </div>
              </div>
            </>
          )}
      </div>

      <div>
        <Modal
          open={openDisbursal}
          onClose={() => {
            setOpenDisbursal(false);
          }}
          width="50vw"
        >
          <>
            <ModalHeader title={"Advance Disbursal"} content={<></>} />
            <div>
              <AdvDisbursalModal
                applicationData={applicationViewDetails}
                disbModalClose={()=>{
                  setOpenDisbursal(false);
                }}
              />
            </div>
          </>
        </Modal>
      </div>
    </div>
  );
};

export default ApplicationView;
