import {
  Button,
  FileUploader,
  Input,
  RadioButton,
  Select2,
  Slider,
} from "../../../../components/ui";
import EmptyProfile from "../../../../assets/images/profile.jpg";
import SelectDate from "../../../../components/ui/select-date";
import moment from "moment";
import { useEffect, useState } from "react";
import PartnerInfo from "../../../../components/partner-info";
import Modal from "../../../../components/ui/modal";
import useApplicationViewById from "../application-view/useApplicationViewById";
import { TApplicationViewDetails, TBillsInfo } from "../../../../types";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import ModalFooter from "../../../../components/ui/modal/modal-footer";
import { FaChevronLeft, FaCircleCheck, FaXmark } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import classes from "./style.module.scss";
import useAllBills from "../Bills/useBills";
import DocumentPreview from "../../../../components/commons/document-preview";
import disbursed from "../../../../assets/images/loanDisburded.svg";
import Spinner from "react-bootstrap/Spinner";
import RecordDisbursalPayout from "../disbursal_payout";
import RecordDisbursal from "./record-disbursal";
import { useDispatch } from "react-redux";
import { updateDisRecordPayoutData } from "../../../../reducers/loansSlice";
import { setPayoutCurrentStep } from "../../../../reducers/tradeSlice";

const AdvDisbursalModal: React.FC<{
  applicationData: TApplicationViewDetails | null;
  disbModalClose?: any;
}> = ({ applicationData, disbModalClose }) => {
  const dispatch = useDispatch();
  const {
    // submitDisbursement,
    // recordDisbursalData,
    // setRecordDisbursalData,
    // handleDateSelection,
    // onUploadReceiptImage,
    // onRemoveReceiptImage,
    // applicationViewDetails,
    // PaymentDoc,
    inputValue,
    setInputValue,
    // loanModal,
    setLoanModal,
    selectedBills,
    setSelectedBills,
    loader,
    disRecordPayoutData,
    loanModalSuccessStatus,
  } = useApplicationViewById({
    applicationId: parseFloat(
      applicationData?.loanAppId ? applicationData?.loanAppId.toString() : "0"
    ),
    disbModalClose: disbModalClose,
  });
  const { getBills, billArray } = useAllBills({
    bills: applicationData?.bills || [],
  });
  const loanModal = loanModalSuccessStatus ? true : false;
  useEffect(() => {
    getBills();
  }, []);

  // const handleBillClick = (bill: TBillsInfo) => {
  //   setSelectedBills((prevSelected) =>
  //     prevSelected.some((selectedBill) => selectedBill.billId === bill.billId)
  //       ? prevSelected.filter((selectedBill) => selectedBill.billId !== bill.billId)
  //       : [...prevSelected, bill]
  //   );
  // };
  // const handleSubmit = () => {
  //   const selectedIds = selectedBills.map((bill) => bill.billId).join(',');
  //   setInputValue(selectedIds);
  // };
  const [activeTab, setActiveTab] = useState("payout");
  useEffect(() => {
    setSelectedBills(billArray);
    const defaultIds = billArray.map((bill) => bill.billId).join(",");
    setInputValue(defaultIds);
    dispatch(
      updateDisRecordPayoutData({
        ...disRecordPayoutData,
        selectedBills: billArray,
      })
    );
  }, [billArray, activeTab]);

  // Handle click for selecting/deselecting bills
  const handleBillClick = (bill: TBillsInfo) => {
    setSelectedBills(
      (prevSelected) =>
        prevSelected.some((selectedBill) => selectedBill.billId === bill.billId)
          ? prevSelected.filter(
              (selectedBill) => selectedBill.billId !== bill.billId
            ) // Deselect bill
          : [...prevSelected, bill] // Select bill
    );
    dispatch(
      updateDisRecordPayoutData({
        ...disRecordPayoutData,
        selectedBills: disRecordPayoutData.selectedBills.some(
          (selectedBill) => selectedBill.billId === bill.billId
        )
          ? disRecordPayoutData.selectedBills.filter(
              (selectedBill) => selectedBill.billId !== bill.billId
            )
          : [...disRecordPayoutData.selectedBills, bill.billId],
      })
    );
  };

  // Update input value when submitting
  const handleSubmit = () => {
    const selectedIds = selectedBills.map((bill) => bill.billId).join(",");
    setInputValue(selectedIds);
  };

  const onBillSelectComplete = () => {
    setStep(0);
  };
  const onBillSelectBack = () => {
    setStep(0);
  };

  const [step, setStep] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [filterBills, setFilterBills] = useState(billArray);

  const filterTraders = () => {
    let filtered = billArray;
    const trimmedSearchText = searchText.trim().toLowerCase();
    if (searchText.length > 0) {
      filtered = filtered.filter(
        (option) =>
          (option.billId &&
            option.billId
              .toString()
              .toLowerCase()
              .includes(trimmedSearchText)) ||
          (option.caBSeq &&
            option.caBSeq.toString().toLowerCase().includes(trimmedSearchText))
      );
    }
    setFilterBills(filtered);
  };
  const handleClear = () => {
    setSearchText("");
    setFilterBills([]);
  };

  useEffect(() => {
    filterTraders();
  }, [filterBills, billArray]);
  const tabs = [
    {
      id: 1,
      name: "Payout",
      img: "",
      to: "payout",
    },
    {
      id: 2,
      name: "Record Payment",
      img: "",

      to: "record_payment",
    },
  ];
  return (
    <>
      <Slider step={step} fullHeight={true}>
        <div>
          <ul className="flex overflow-x-auto gap-4 px-8 md:overflow-x-none border-b md:border-none">
            {tabs.map((tab, i) => (
              <li
                key={`tab-${i}`}
                className={`cursor-pointer whitespace-nowrap px-2 py-2 rounded-t-lg bg-white-200 ${
                  activeTab === tab.to ? "border-green-600 border-b-4" : ""
                }`}
                onClick={() => {
                  setActiveTab(tab.to);
                  setSelectedBills(selectedBills);
                  dispatch(
                    updateDisRecordPayoutData({
                      ...disRecordPayoutData,
                      tenure: "",
                      pmtMode: "",
                      disbDate: moment().format("YYYY-MM-DD"),
                      comments: "",
                    })
                  );
                  dispatch(setPayoutCurrentStep(1));
                }}
              >
                {tab.name}
              </li>
            ))}
          </ul>
          <div>
            {activeTab == "payout" && (
              <RecordDisbursalPayout
                applicationData={applicationData}
                disbModalClose={disbModalClose}
                selectedBills={selectedBills}
                setStep={setStep}
                inputValue={inputValue}
              />
            )}
            {activeTab == "record_payment" && (
              <RecordDisbursal
                applicationData={applicationData}
                disbModalClose={disbModalClose}
                setStep={setStep}
                fromPayout={false}
                inputValue={inputValue}
              />
            )}
          </div>
        </div>
        <div className="px-4 mt-2 h-full relative">
          <div className="flex gap-6">
            <div className="grow">
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "100%" }}
              >
                <div className=" relative">
                  <FaSearch
                    className=" absolute color-primary"
                    size={15}
                    style={{ top: "16px", left: "6px" }}
                  />
                  <Input
                    type="text"
                    palceholder="Search by Bill Id"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    inputStyle={{
                      textIndent: "22px",
                      padding: "10px 7px",
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                  <div>
                    <IoMdCloseCircleOutline
                      onClick={() => {
                        handleClear();
                      }}
                      className="absolute color-primary cursor-pointer w-10"
                      style={{
                        top: "32%",
                        right: "1rem",
                        fontSize: "1.2rem",
                        color: "gray",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex border rounded-md mt-3 py-2">
            <div className="grow flex flex-col items-center border-r text-xs">
              <span className="font-semibold">Amount Requested</span>
              <span>
                {getCurrencyNumberWithSymbol(applicationData?.disbAmt, true)}
              </span>
            </div>
            <div className="grow flex flex-col items-center text-xs">
              <span className="font-semibold">Disbursal Amount </span>
              <span>
                {getCurrencyNumberWithSymbol(applicationData?.disbAmt, true)}
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              className="px-2 rounded-t-lg"
              style={{ background: "var(--ono-select-background)" }}
            >
              <div className="flex text-sm font-semibold h-10 items-center">
                <span className="inline-flex px-2" style={{ width: "4rem" }}>
                  Bill Id
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Date
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Total Bill Amount
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Paid Amount
                </span>
              </div>
            </div>
            <div
              className="border px-2 overflow-auto"
              style={{ maxHeight: "calc(70vh - 21rem)" }}
              id="scroll_style"
            >
              {(searchText
                ? filterBills.filter((bill) => {
                    return (
                      bill.caBSeq.toString().includes(searchText) ||
                      moment(bill.billDate)
                        .format("DD MMM YYYY")
                        .includes(searchText)
                    );
                  })
                : filterBills
              ).map((bill) => {
                const selected = selectedBills.some(
                  (selectedBill) => selectedBill.billId === bill.billId
                );
                return (
                  <div
                    key={bill.billId}
                    className={`flex text-xs h-10 items-center border my-2 br-10 cursor-pointer ${classes.billItem}`}
                    data-selected={selected}
                    onClick={() => {
                      handleBillClick(bill);
                    }}
                  >
                    <span
                      className={`inline-flex px-2 ${
                        selected ? "text-green-500" : "text-black"
                      }
                      style={{ width: "4rem" }}`}
                    >
                      {bill.caBSeq}
                    </span>
                    <span
                      className={`grow-[2] shrink-0 basis-0 px-2 text-center ${
                        selected ? "text-green-500" : "text-black"
                      }`}
                    >
                      {moment(bill.billDate).format("DD-MMM-YYYY")}
                    </span>
                    <span
                      className={`grow-[2] shrink-0 basis-0 px-2 text-center ${
                        selected ? "text-green-500" : "text-black"
                      }`}
                    >
                      {getCurrencyNumberWithSymbol(bill.billAmt, true)}
                    </span>
                    <span className="grow-[2] shrink-0 basis-0 px-2 text-center relative color-red">
                      {selected === true && (
                        <FaCircleCheck
                          className={`absolute right-2 text-xl z-10 ${"color-primary"}`}
                          style={{ top: "-8px" }}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <ModalFooter>
            <Button
              variant="transparent"
              onClick={() => {
                onBillSelectBack();
              }}
            >
              <FaChevronLeft />
              Back
            </Button>
            <Button
              onClick={() => {
                onBillSelectComplete();
                handleSubmit();
              }}
              className="ml-auto"
            >
              Update
            </Button>
          </ModalFooter>
        </div>
      </Slider>
      <div>
        {/* {loanModalSuccessStatus} */}
        <Modal
          open={loanModal}
          onClose={() => {
            setLoanModal(false);
          }}
          width="30vw"
          height="40vh"
        >
          <>
            <div className="flex h-full items-center justify-center">
              <div className="flex flex-col items-center px-4 py-4">
                <img src={disbursed} alt="loan disbursed" className="mb-4" />
                <h1 className="text-primary text-lg font-bold">
                  Loan Successfully Disbursed!
                </h1>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};
export default AdvDisbursalModal;
