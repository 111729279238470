import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { TTradeDocument } from "../../types";
type Tpayment = {
  id: number;
  tradeId: number;
  date: string;
  pmtType: "ADVANCE" | "PARTIAL_PAYMENT" | "FINAL_PAYMENT";
  pmtMode: string;
  amount: number;
  balance: number;
  reqId: number;
  images: Array<TTradeDocument>;
  comments: string;
  source: string;
  inOutWard: string;
};
type TImage = {
  addedBy: number;
  id: number;
  imgFrom: string;
  reqId: number;
  status: number;
  type: string;
  url: string;
};
const usePaymentDetail = ({ tradeId }: { tradeId: number }) => {
  const { handleGetCall } = useAxiosClient();
  const { tradeDetails, adminId } = useSelector((state: TRootState) => ({
    tradeDetails: state.trades.tradeDetails,
    adminId: state.auth.adminId,
  }));
  const [activeTab, setActiveTab] = useState("outward_payment");
  const [inwardPayment, setInwordpayment] = useState<{
    pmts: Array<Tpayment>;
    images: Array<TImage>;
  } | null>(null);
  const [outWardPayment, setOutWordpayment] = useState<{
    pmts: Array<Tpayment>;
    images: Array<TImage>;
  } | null>(null);
  useEffect(() => {
    if (activeTab === "inward_payment" && inwardPayment === null) {
      handleGetCall<{ pmts: Array<Tpayment>; images: Array<TImage> }>(
        `/admin/trade-platform/trades/adminId/${adminId}/tradeId/${tradeId}/inoutward/{inoutward}?inoutward=INWARD`
      ).then(({ data }) => {
        setInwordpayment({ pmts: data.pmts, images: data.images });
      });
    }
    if (activeTab === "outward_payment" && outWardPayment === null) {
      handleGetCall<{ pmts: Array<Tpayment>; images: Array<TImage> }>(
        `/admin/trade-platform/trades/adminId/${adminId}/tradeId/${tradeId}/inoutward/{inoutward}?inoutward=OUTWARD`
      ).then(({ data }) => {
        setOutWordpayment({ pmts: data.pmts, images: data.images });
      });
    }
  }, [activeTab]);
  return {
    activeTab,
    setActiveTab,
    tradeDetails,
    inwardPayment,
    outWardPayment,
  };
};
export default usePaymentDetail;
