import { useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import { getCurrencyNumberWithOutSymbol } from "../../../../helpers/get-currency-number";
import NodataAvailable from "../../../../assets/images/NodataAvailable.svg";
import moment from "moment";
const RepaymentHistory: React.FC<{}> = ({}) => {
  const { InitApplicationDetail } = useSelector((state: TRootState) => ({
    InitApplicationDetail: state.loans.applicationViewDetails,
  }));

  return (
    <>
      <div className="bg-light-primary px-3 py-2 mt-3 rounded-t-md">
        <span className="text-sm font-semibold">Repayment History</span>
      </div>
      <div className="overflow-auto bg-white">
        <table className="min-w-full border-collapse border">
          <thead>
            <tr className="">
              <th className="px-2 py-2 border text-sm font-medium">#</th>
              <th className="px-2 py-2 border text-sm font-medium">Ref ID</th>
              <th className="px-2 py-2 border text-sm font-medium">
                Repaid Amount(₹)
              </th>
              <th className="px-2 py-2 border text-sm font-medium">
                Repayable Balance(₹)
              </th>
            </tr>
          </thead>
          <tbody>
            {InitApplicationDetail?.loanSmry?.repayHist &&
            InitApplicationDetail.loanSmry.repayHist.length > 0 ? (
              InitApplicationDetail?.loanSmry?.repayHist?.map((loan, index) => (
                <tr key={loan.id} className="hv-primary cursor-pointer">
                  <td className="px-2 py-2 border">{index + 1}</td>
                  <td className="px-2 py-2 border">
                    <div className="color-blue">{loan.id}</div>
                    <div className="text-sm">
                      {moment(loan.date).format("DD-MMM-YYYY")}
                    </div>
                  </td>
                  <td className="px-2 py-2 border">
                    {getCurrencyNumberWithOutSymbol(loan.amount) || "0"}
                  </td>
                  <td className="px-2 py-2 border color-primary">
                  {loan.balance > 0 ? getCurrencyNumberWithOutSymbol(loan.balance) : "0"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center py-4">
                  <div className="flex items-center justify-center py-2">
                    <img src={NodataAvailable} alt="image" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default RepaymentHistory;
