import { useParams } from "react-router-dom";
import closedLoan from "../../../../assets/images/closeLoan.svg";
import {
  Button,
  FileUploader,
  Input,
  RadioButton,
  Select2,
} from "../../../../components/ui";
import useApplicationViewById from "../application-view/useApplicationViewById";
import PartnerInfo from "../../../../components/partner-info";
import SelectDate from "../../../../components/ui/select-date";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import DocumentPreview from "../../../../components/commons/document-preview";
import { Spinner } from "react-bootstrap";
import { TApplicationViewDetails } from "../../../../types";
import { FaXmark } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { updateDisRecordPayoutData } from "../../../../reducers/loansSlice";
import {
  getLoanPartyType,
  maskAccountNumber,
} from "../../../../helpers/getText";
import { useEffect } from "react";
const RecordDisbursal: React.FC<{
  applicationData: TApplicationViewDetails | null;
  disbModalClose: any;
  setStep?: any;
  fromPayout: boolean;
  inputValue?: any;
}> = ({ applicationData, disbModalClose, setStep, fromPayout, inputValue }) => {
  const dispatch = useDispatch();
  const {
    submitDisbursement,
    recordDisbursalData,
    setRecordDisbursalData,
    handleDateSelection,
    onUploadReceiptImage,
    onRemoveReceiptImage,
    applicationViewDetails,
    PaymentDoc,
    setLoanModal,
    loader,
    disRecordPayoutData,
  } = useApplicationViewById({
    applicationId: parseFloat(
      applicationData?.loanAppId ? applicationData?.loanAppId.toString() : "0"
    ),
    disbModalClose: disbModalClose,
  });
  useEffect(() => {
    if (disRecordPayoutData?.pmtMode != "") {
      setRecordDisbursalData({
        ...recordDisbursalData,
        loanDate: disRecordPayoutData?.disbDate,
        pmtMode: disRecordPayoutData?.pmtMode,
        comment: disRecordPayoutData?.comments,
        tenure: disRecordPayoutData?.tenure,
      });
    }
  }, [fromPayout]);
  return (
    <>
      <div
        style={{
          height: fromPayout ? "" : "calc(100vh - 18rem)",
          overflowY: "auto",
          width: "100%",
        }}
        id="scroll_style"
      >
        <div className="px-8 py-4">
          <div className="flex gap-4">
            <div className=" flex flex-col " style={{ width: "50%" }}>
              {fromPayout && <span className="text-sm pb-1">To</span>}
              <div className="grow-[2] border br-10 basis-0 flex flex-col px-6 py-4">
                <PartnerInfo
                  profilePic={applicationData?.profilePic || ""}
                  name={applicationData?.partyName || ""}
                  shortName={applicationData?.shortCode || ""}
                  partnerType={getLoanPartyType(applicationData?.partyType)}
                  partnerId={applicationData?.partyId}
                  partnerMobile={applicationData?.partyMobile || ""}
                  partyName={applicationData?.addressLine}
                  fromLoansParty={true}
                />
              </div>
            </div>
            {fromPayout && (
              <div className=" flex flex-col " style={{ width: "50%" }}>
                <span className="text-sm pb-1">Fund Account ID</span>
                <div className="grow-[2] border br-10 basis-0 flex flex-col px-6 py-4">
                  <span className="font-semibold text-sm">
                    {" "}
                    {applicationData?.payoutAcc?.rzpFa}
                  </span>
                  <span className="color-sub-text text-sm">
                    {applicationData?.payoutAcc?.actNum
                      ? maskAccountNumber(applicationData?.payoutAcc?.actNum)
                      : applicationData?.payoutAcc?.actNum}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div
            className="grow-[2] basis-0 flex flex-col pt-4"
            style={{ width: "100%" }}
          >
            <span className="text-sm pb-1">Bank Name</span>
            <Input
              className="text-sm font-semibold"
              type="text"
              value={applicationData?.payoutAcc?.bank}
              disabled
              inputStyle={{ width: "100%" }}
            />
          </div>

          <div className="flex" style={{ width: "100%" }}>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "50%" }}
            >
              <span className="text-sm pb-1">Bank Account Number</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={applicationData?.payoutAcc?.actNum}
                disabled
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
              style={{ width: "50%" }}
            >
              <span className="text-sm pb-1">IFSC Code</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={applicationData?.payoutAcc?.ifsc}
                disabled
              />
            </div>
          </div>

          <div className="flex" style={{ width: "100%" }}>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "50%" }}
            >
              <span className="text-sm pb-1">Disbursement Date</span>
              <SelectDate
                onChange={handleDateSelection}
                selectedDate={recordDisbursalData?.loanDate}
                styles={{ padding: "14px" }}
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
              style={{ width: "50%" }}
            >
              <span className="text-sm pb-1">
                Select Bills{" "}
                <span
                  className="text-sm color-blue cursor-pointer"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  See All
                </span>
              </span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={inputValue}
              />
            </div>
          </div>
          <div className="flex">
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">
                Advance Disbursal Amount <span className="color-red">*</span>
              </span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={recordDisbursalData?.reqLoanAmt}
                onChange={(e) => {
                  const value = e.target.value;
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    reqLoanAmt: value === "" ? "" : parseFloat(value) || 0,
                  });
                  dispatch(
                    updateDisRecordPayoutData({
                      ...disRecordPayoutData,
                      disbAmt: value === "" ? "" : parseFloat(value) || 0,
                    })
                  );
                }}
                inputStyle={{ width: "100%" }}
              />
            </div>
            {applicationViewDetails?.loanType == "WORKING CAPITAL" && (
              <div
                className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
                style={{ width: "100%" }}
              >
                <span className="text-sm pb-1">
                  Tenure <span className="color-red">*</span>
                </span>
                <div className="relative h-[48px]">
                  <Select2
                    value={recordDisbursalData?.tenure}
                    lable=""
                    options={[
                      {
                        label: "30 Days",
                        value: "30",
                      },
                      {
                        label: "60 Days",
                        value: "60",
                      },
                      {
                        label: "90 Days",
                        value: "90",
                      },
                    ]}
                    onChange={(selectedDays: any) => {
                      setRecordDisbursalData({
                        ...recordDisbursalData,
                        tenure: selectedDays.value,
                      });
                      dispatch(
                        updateDisRecordPayoutData({
                          ...disRecordPayoutData,
                          tenure: selectedDays.value,
                        })
                      );
                    }}
                    styles={{ padding: "12px" }}
                  />
                </div>
              </div>
            )}
          </div>
          {fromPayout ? (
            ""
          ) : (
            <div className="flex" style={{ width: "100%" }}>
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "50%" }}
              >
                <span className="text-sm pb-1">UTR Number</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={recordDisbursalData?.utr}
                  onChange={(e) => {
                    const value = e.target.value;
                    setRecordDisbursalData({
                      ...recordDisbursalData,
                      utr: value === "" ? "" : value || "",
                    });
                    dispatch(
                      updateDisRecordPayoutData({
                        ...disRecordPayoutData,
                        utr: value === "" ? "" : value || "",
                      })
                    );
                  }}
                  inputStyle={{ width: "100%" }}
                />
              </div>
            </div>
          )}
          <div className="flex" style={{ width: "100%" }}>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "30%" }}
            >
              <span className="text-sm pb-1">Amount Requested</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={
                  getCurrencyNumberWithSymbol(applicationData?.reqLoanAmt) || "0"
                }
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
              style={{ width: "30%" }}
            >
              <span className="text-sm pb-1">CASH Limit</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={
                  getCurrencyNumberWithSymbol(applicationData?.eligibleLimit) ||
                  "0"
                }
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
              style={{ width: "30%" }}
            >
              <span className="text-sm pb-1">CASH Available</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={
                  getCurrencyNumberWithSymbol(
                    applicationData?.availableLimit
                  ) || "0"
                }
              />
            </div>
          </div>
          <div
            className="grow-[2] basis-0 flex flex-col pt-4"
            style={{ width: "100%" }}
          >
            <span className="text-sm">
              Payment Mode <span className="color-red">*</span>
            </span>
            {fromPayout ? (
              <RadioButton
                displayType="horizontal"
                data={[
                  {
                    label: "IMPS",
                    value: "IMPS",
                  },
                  {
                    label: "NEFT",
                    value: "NEFT",
                  },
                  {
                    label: "RTGS",
                    value: "RTGS",
                  },
                ]}
                value={recordDisbursalData.pmtMode}
                pointer={{ label: "label", value: "value" }}
                onChange={(selectedPaymentMode) => {
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    pmtMode: selectedPaymentMode,
                  });
                  dispatch(
                    updateDisRecordPayoutData({
                      ...disRecordPayoutData,
                      pmtMode: selectedPaymentMode,
                    })
                  );
                }}
                styles={{ display: "flex" }}
              />
            ) : (
              <RadioButton
                displayType="horizontal"
                data={[
                  {
                    label: "IMPS",
                    value: "IMPS",
                  },
                  {
                    label: "NEFT",
                    value: "NEFT",
                  },
                  {
                    label: "UPI",
                    value: "UPI",
                  },
                  {
                    label: "RTGS",
                    value: "RTGS",
                  },
                  {
                    label: "CASH",
                    value: "CASH",
                  },
                  {
                    label: "CHEQUE",
                    value: "CHEQUE",
                  },
                ]}
                value={recordDisbursalData.pmtMode}
                pointer={{ label: "label", value: "value" }}
                onChange={(selectedPaymentMode) => {
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    pmtMode: selectedPaymentMode,
                  });
                  dispatch(
                    updateDisRecordPayoutData({
                      ...disRecordPayoutData,
                      pmtMode: selectedPaymentMode,
                    })
                  );
                }}
                styles={{ display: "flex" }}
              />
            )}
            {fromPayout &&
              <div>
                {(disRecordPayoutData.pmtMode == "IMPS" ||
                  disRecordPayoutData.pmtMode == "NEFT") && (
                    <span className="text-sm font-semibold color-primary">
                      Rs. 1 Onwards
                    </span>
                  )}
                {disRecordPayoutData.pmtMode == "RTGS" && (
                  <span className="text-sm font-semibold color-primary">
                    Rs. 2 Lacs Onwards
                  </span>
                )}
              </div>
            }
          </div>
          <div
            className="grow-[2] basis-0 flex flex-col pt-4"
            style={{ width: "100%" }}
          >
            <span className="text-sm pb-1">Comments</span>
            <Input
              className="text-sm font-semibold"
              type="text"
              value={recordDisbursalData?.comment}
              onChange={(e) => {
                setRecordDisbursalData({
                  ...recordDisbursalData,
                  comment: e.target.value,
                });
                dispatch(
                  updateDisRecordPayoutData({
                    ...disRecordPayoutData,
                    comments: e.target.value,
                  })
                );
              }}
              inputStyle={{ width: "100%" }}
            />
          </div>
          {fromPayout ? (
            ""
          ) : (
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">
                Upload Payment Receipt <span className="color-red">*</span>
              </span>

              <div className="flex flex-row overflow-auto w-full hide-scroll-bar">
                <div
                  className={`${PaymentDoc.length > 0 ? "w-1/2" : "w-full"
                    } shrink-0 px-2`}
                >
                  <FileUploader
                    onChange={(e) => {
                      if (e.target.files) {
                        onUploadReceiptImage("PAYMENT_RECEIPT", e.target.files);
                      }
                    }}
                  />
                </div>
                {applicationViewDetails?.kycDocs != null &&
                  applicationViewDetails?.kycDocs
                    .filter(
                      (doc) =>
                        doc.docType == "PAYMENT_RECEIPT" && doc.status != 0
                    )
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "10rem",
                        }}
                        key={`PAYMENT_RECEIPT-${i}`}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={
                              image.docUrl.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.docUrl, true, image.id);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                {PaymentDoc.filter((doc) => doc.type == "PAYMENT_RECEIPT").map(
                  (image, i) => (
                    <div
                      className="shrink-0 overflow-hidden p-2 rounded relative"
                      style={{
                        width: "40%",
                        height: "10rem",
                      }}
                      key={`PAYMENT_RECEIPT-${i}`}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveReceiptImage(image.url);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <div className="border-b px-4"></div>
      </div>
      {fromPayout ? (
        ""
      ) : (
        <div className="modal-header flex justify-between items-center py-4 px-6">
          <div>
            <Button
              text="CANCEL"
              className="w-[100px] color-primary"
              variant="transparent"
              onClick={() => {
                setLoanModal(false);
                disbModalClose(false);
              }}
            />
          </div>
          <div>
            <Button
              className="w-[200px]"
              disabled={loader}
              onClick={() => {
                if (!loader) submitDisbursement();
              }}
            >
              {loader && (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="me-2"
                />
              )}
              SUBMIT
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default RecordDisbursal;
