import { FaBackspace, FaEdit } from "react-icons/fa";
import { FaBackward } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "../../../components/ui";
import CardTitleBg from "../../../components/ui/cardTitleBg";
import rupee from "../../../assets/images/rupee.svg";
import user from "../../../assets/images/single_bill.svg";
import DocumentPreview from "../../../components/commons/document-preview";
import PartnerInfo from "../../../components/partner-info";
import { useEffect, useState } from "react";
import Modal from "../../../components/ui/modal";
import ModalHeader from "../../../components/ui/modal/modal-header";
import EditBeneficiary from "./edit-beneficiary";
import { ArrowBack } from "@mui/icons-material";
import useBeneficiaryDetailsById from "../../../hooks/useBeneficiaryDetailsById";
import { getCurrencyNumberWithSymbol } from "../../../helpers/get-currency-number";
import moment from "moment";
import useBorrowerDetailsById from "../../../hooks/useBorrowerDetailsById";
import timer from "../../../assets/images/timer-img.png";
import hold from "../../../assets/images/hold.svg";
import reject from "../../../assets/images/reject.svg";
import tickmark from "../../../assets/images/verified.svg";
import blocked from "../../../assets/images/blocked.svg";
import { getMaskedMobileNumber } from "../../../helpers/get-currency-number";
import DocumentViewer from "../../../components/commons/document-preview";
import PayoutDetails from "../CustomerDetails/PayoutDetails/PayoutDetails";
import { useDispatch } from "react-redux";
import { setPayoutDetails } from "../../../reducers/tradeSlice";

const BeneficiaryDetails = () => {
  const navigate = useNavigate();
  const [editBeneficiaryModal, setEditBeneficiaryModal] = useState(false);
  const params = useParams();

  const { borrowerDetails, getBorrowerDetail } = useBorrowerDetailsById({
    appId: parseInt(params.appId || "0"),
    type: params.type || "",
    fromBorrowerPAge: false,
  });

  const { beneficiaryDetails, getBeneficiaryDetail, role } =
    useBeneficiaryDetailsById({
      appId: parseInt(params.partyAppId || "0"),
      loanAccNo: parseInt(params.loanAccNo || "0"),
    });

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getBeneficiaryDetail();
    getBorrowerDetail();
  }, [params.appId, params.appId]);
  useEffect(() => {
    getBeneficiaryDetail();
    getBorrowerDetail();
  }, [params, refresh]);
  return (
    <>
      <div className="border-t overflow-hidden bg-white px-4">
        <div className="flex  text-sm py-4 cursor-pointer items-center">
          <span
            onClick={() => {
              navigate("/customers");
            }}
          >
            <ArrowBack />
          </span>
          <div
            className="grow-[2.5] basis-0 flex px-4 border-r"
            style={{ flexBasis: "4rem", width: "40%" }}
          >
            <PartnerInfo
              profilePic=""
              name={borrowerDetails?.fullName || ""}
              shortName={""}
              partnerType={"Application ID "}
              partnerId={borrowerDetails?.appId}
              partnerMobile={borrowerDetails?.mobile || ""}
              partyName={borrowerDetails?.addressLine || ""}
            />
          </div>
          <div
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm color-sub-text">ONO Risk Score (ORS)</span>
            <span className="font-semibold text-sm ">
              {borrowerDetails?.onoScore || "-"}
            </span>
          </div>
          <span
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            {" "}
            <span className="text-sm color-sub-text">Cash Limit</span>
            <span className="font-semibold text-sm">
              {" "}
              {borrowerDetails?.cashProfile?.eligibleLimit || "0"}
            </span>
          </span>

          <span
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            {" "}
            <span className="text-sm color-sub-text">Available Limit</span>
            <span className="font-semibold text-sm">
              {borrowerDetails?.cashProfile?.availableLimit || "0"}
            </span>
          </span>
          <div
            className="grow-[2] basis-0 px-2 flex flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm color-sub-text">Interest Rate</span>
            <span className="font-semibold text-sm">
              {borrowerDetails?.interestRate
                ? borrowerDetails?.interestRate + "%"
                : "-"}
            </span>
          </div>
          <div
            className="grow-[2] basis-0 flex px-2 flex-col"
            style={{ width: "20%" }}
          >
            <span className="text-sm color-sub-text">Tenure Period</span>
            <span className="font-semibold text-sm ">
              {borrowerDetails?.tenure || 0 + "Days" || "-"}
            </span>
          </div>
          {/* <span className="grow-[0.5] basis-0 px-2"></span> */}
        </div>
      </div>
      <div className="container mx-auto py-4">
        <div className="flex gap-2 border-b pb-3 font-semibold">
          <span>
            {beneficiaryDetails?.farmerName +
              (beneficiaryDetails?.shortName
                ? "-" + beneficiaryDetails?.shortName
                : "")}
          </span>
        </div>
        <div
          className="flex overflow-y-auto gap-4"
          style={{ height: "calc(100vh - 14rem)" }}
        >
          <div className="w-[40%]">
            {/* borrower details */}
            <div>
              <CardTitleBg
                titile={
                  <div className="flex items-center">
                    <span>Beneficiary Details</span>
                    {role === "ADMIN" && beneficiaryDetails?.status != 'EXPIRED' && (
                      <Button
                        variant="transparent"
                        className="ml-auto gap-1"
                        size="small"
                        onClick={() => {
                          setEditBeneficiaryModal(true);
                        }}
                      >
                        <FaEdit className="" />
                        Edit
                      </Button>
                    )}
                  </div>
                }
                titileClassName="px-4"
                className="bg-gray-50 mt-4"
              >
                <div className="px-4 py-2">
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="" style={{ width: "66%" }}>
                      <PartnerInfo
                        profilePic={beneficiaryDetails?.profilePic || ""}
                        name={beneficiaryDetails?.farmerName || ""}
                        shortName={beneficiaryDetails?.shortName || ""}
                        partnerType={beneficiaryDetails?.partyType || ""}
                        partnerId={beneficiaryDetails?.farmerId || ""}
                        partnerMobile={beneficiaryDetails?.mobile || ""}
                        partyName={beneficiaryDetails?.addressLine}
                      />
                    </div>

                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        ONO Risk Score (ORS)
                      </div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.onoScore || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Cash Limit</div>
                      <div className="font-semibold text-sm">{"-"}</div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Available Limit
                      </div>
                      <div className="font-semibold text-sm">{"-"}</div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Renewal Date</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.renewDate
                          ? moment(beneficiaryDetails?.renewDate).format(
                            "DD-MMM-YYYY"
                          )
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3  border-b">
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Total Loans</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.totalLoans || 0}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Active Loans</div>
                      <div className="font-semibold text-sm">
                        {" "}
                        {beneficiaryDetails?.activeLoans || 0}
                      </div>
                    </div>
                    <div className="flex-1"></div>
                  </div>

                  <div className="flex justify-between gap-2 py-3  ">
                    <div className="" style={{ width: "66%" }}>
                      <div className="text-sm color-sub-text">Comments</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.comments || "-"}
                      </div>
                    </div>
                    <div className="flex-1" style={{ width: "34%" }}>
                      <div className="text-sm color-sub-text">Status</div>
                      <div className="font-semibold text-sm">
                        {/* {beneficiaryDetails?.appStatus || "-"} */}

                        {beneficiaryDetails?.status === "ACTIVE" && (
                          <div className="flex cursor-pointer color-primary font-semibold">
                            <div>
                              {" "}
                              <img src={tickmark} className="w-5 h-5" />{" "}
                            </div>
                            <div className="ml-1">
                              {beneficiaryDetails?.status}
                            </div>
                          </div>
                        )}

                        {beneficiaryDetails?.status === "UNDER REVIEW" && (
                          <div className="flex cursor-pointer color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={timer} className="w-5 h-5" />{" "}
                            </div>
                            <div className="ml-1">
                              {beneficiaryDetails?.status}
                            </div>
                          </div>
                        )}

                        {beneficiaryDetails?.status === "HOLD" && (
                          <div className="flex cursor-pointer flex color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={hold} className="w-5 h-5" />{" "}
                            </div>
                            <div className="ml-1">
                              {beneficiaryDetails?.status}
                            </div>
                          </div>
                        )}

                        {beneficiaryDetails?.status === "REJECTED" && (
                          <div className="flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img src={reject} className="w-5 h-5" />{" "}
                            </div>
                            <div className="ml-1">
                              {beneficiaryDetails?.status}
                            </div>
                          </div>
                        )}

                        {beneficiaryDetails?.status === "BLOCKED" && (
                          <div className="flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img src={blocked} className="w-5 h-5" />{" "}
                            </div>
                            <div className="ml-1">
                              {beneficiaryDetails?.status}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardTitleBg>

              <CardTitleBg
                titile={
                  <div className="flex items-center">
                    <span>Bank Details</span>
                  </div>
                }
                titileClassName="px-4"
                className="bg-gray-50 mt-4"
              >
                <div className="px-4 py-2">
                  <div className="flex justify-between gap-2 py-3">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">
                          Beneficiary Name
                        </div>
                        <div className="font-semibold text-sm">
                          {beneficiaryDetails?.farmerName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Mobile No</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.mobile || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">
                          Account Holder
                        </div>
                        <div className="font-semibold text-sm">
                          {beneficiaryDetails?.accHolder || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Account Number
                      </div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.accNum || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">Bank Name</div>
                        <div className="font-semibold text-sm">
                          {beneficiaryDetails?.bankName || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">IFSC Code</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.ifscCode || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </CardTitleBg>
              <PayoutDetails
                customerId={beneficiaryDetails?.benId || 0}
                isCustomerActive={beneficiaryDetails?.status != 'EXPIRED'}
                payouts={beneficiaryDetails?.payAcc || []}
                onAddAcccount={() => {
                  dispatch(
                    setPayoutDetails({
                      traderId: beneficiaryDetails?.benId || 0,
                      traderType: "",
                      contactNumber: beneficiaryDetails?.mobile!,
                      emailNumber: "",
                      isApiCall: true,
                      type: "BENIFICIARY",
                    })
                  );
                }}
                onSubmit={() => {
                  setRefresh(!refresh);
                  // onSubmit();
                }}
                fromBorrower={true}
              />

              <CardTitleBg
                titile={
                  <div className="flex items-center">
                    <span>Partner Details</span>
                  </div>
                }
                titileClassName="px-4"
                className="bg-gray-50 mt-4"
              >
                <div className="px-4 py-2">
                  <div className="flex justify-between gap-2 py-3">
                    <div className="flex-1 flex gap-3">
                      <img src={user} className="w-8" />
                      <div>
                        <div className="text-sm color-sub-text">User Type</div>
                        <div className="font-semibold text-sm">
                          {" "}
                          {beneficiaryDetails?.partyType || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">Short Name</div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.shortName || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between gap-2 py-3 border-b">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">Address</div>
                        <div className="font-semibold text-sm">
                          {beneficiaryDetails?.addressLine} ,
                          {beneficiaryDetails?.dist},{beneficiaryDetails?.state}{" "}
                          {beneficiaryDetails?.pincode}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 border-b">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">
                          Aadhar Number
                        </div>
                        <div className="font-semibold text-sm">
                          {getMaskedMobileNumber(
                            beneficiaryDetails?.aadhar || "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Mobile Number
                      </div>
                      <div className="font-semibold text-sm">
                        {beneficiaryDetails?.mobile || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 py-3 ">
                    <div className="flex-1 flex gap-3">
                      <div style={{ marginLeft: "43px" }}>
                        <div className="text-sm color-sub-text">
                          Opening Balance
                        </div>
                        <div className="font-semibold text-sm">
                          {getCurrencyNumberWithSymbol(
                            beneficiaryDetails?.openingBal
                          ) || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm color-sub-text">
                        Onboarding Date
                      </div>
                      <div className="font-semibold text-sm">
                        {moment(beneficiaryDetails?.openingBalDate).format(
                          "DD-MMM-YYYY"
                        ) || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </CardTitleBg>
            </div>
          </div>
          <div className="w-[60%]">
            <CardTitleBg
              titile={
                <div className="flex items-center">
                  <span>Documents</span>
                </div>
              }
              titileClassName="px-4"
              className="bg-gray-50 mt-4"
            >
              <div className="px-4 py-2">
                <div className="flex flex-col justify-between gap-2 py-3 ">
                  <div className="pb-2">
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Aadhar Card :
                    </h2>
                    {beneficiaryDetails?.adhrName ? (
                      <div className="flex gap-4 py-4">
                        <div
                          className="shrink-0 overflow-hidden border p-2 rounded relative"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                          key={`arival-doc`}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={beneficiaryDetails?.photo}
                          >
                            <DocumentViewer
                              docType="image"
                              url={beneficiaryDetails?.photo || ""}
                            />
                          </a>
                        </div>
                        <div
                          className="border p-2 flex gap-4 justify-center flex-col rounded relative"
                          style={{
                            width: "100%",
                            height: "8rem",
                          }}
                        >
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-sm color-sub-text">Name</div>
                              <div className="font-medium text-sm">
                                {beneficiaryDetails?.adhrName
                                  ? beneficiaryDetails?.adhrName
                                  : "-"}{" "}
                              </div>
                            </div>

                            <div className="flex-1">
                              <div className="text-sm color-sub-text">
                                Aadhaar Number
                              </div>
                              <div className="font-medium text-sm">
                                {beneficiaryDetails?.aadhar
                                  ? beneficiaryDetails?.aadhar
                                  : "-"}{" "}
                              </div>
                            </div>

                            <div className="flex-1">
                              <div className="text-sm color-sub-text">
                                Mobile Number
                              </div>
                              <div className="font-medium text-sm">
                                {beneficiaryDetails?.adhrMobile
                                  ? beneficiaryDetails?.adhrMobile
                                  : "-"}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-wrap gap-4 py-4">
                        {/* Aadhar Front */}
                        {beneficiaryDetails?.kycDocs.filter(
                          (doc) => doc.docType === "AADHAR_FRONT"
                        ).length! > 0 ? (
                          beneficiaryDetails?.kycDocs
                            .filter((doc) => doc.docType === "AADHAR_FRONT")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative flex items-center"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                                key={`aadhar-front-${i}`} // Use unique keys
                              >
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.docUrl}
                                >
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))
                        ) : (
                          <div
                            className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                            style={{
                              width: "40%",
                              height: "8rem",
                            }}
                          >
                            Not Uploaded Aadhar
                          </div>
                        )}

                        {/* Aadhar Back */}
                        {beneficiaryDetails?.kycDocs.filter(
                          (doc) => doc.docType === "AADHAR_BACK"
                        ).length! > 0 &&
                          beneficiaryDetails?.kycDocs
                            .filter((doc) => doc.docType === "AADHAR_BACK")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative flex items-center"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                                key={`aadhar-back-${i}`} // Use unique keys
                              >
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.docUrl}
                                >
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      Permanent Account Number(PAN) :
                    </h2>
                    <div className="flex gap-4 py-4">
                      {beneficiaryDetails?.kycDocs.filter(
                        (doc) => doc.docType == "PAN_CARD"
                      ).length! > 0 ? (
                        <>
                          {beneficiaryDetails?.kycDocs
                            .filter((doc) => doc.docType == "PAN_CARD")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "20%",
                                  height: "8rem",
                                }}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded PAN
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <h2 className="color-text-primary pb-2 border-b text-sm">
                      PassBook / Cheque :
                    </h2>
                    <div className="flex gap-4 py-4">
                      {beneficiaryDetails?.kycDocs.filter(
                        (doc) => doc.docType == "BANK_STATEMENT" || doc.docType === "BANK_PASSBOOK"
                      ).length! > 0 ? (
                        <>
                          {beneficiaryDetails?.kycDocs
                            .filter((doc) => doc.docType == "BANK_STATEMENT" || doc.docType === "BANK_PASSBOOK")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "20%",
                                  height: "8rem",
                                }}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded BANK_STATEMENT
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardTitleBg>
          </div>
        </div>
      </div>
      {editBeneficiaryModal && (
        <Modal
          open={editBeneficiaryModal}
          onClose={() => {
            setEditBeneficiaryModal(false);
          }}
          width="80vw"
        >
          <>
            <ModalHeader title={"Edit Beneficiary"} content={<></>} />
            <EditBeneficiary
              onEditBeneficiaryModalClose={setEditBeneficiaryModal}
              type={"SELLER"}
            />
          </>
        </Modal>
      )}
    </>
  );
};
export default BeneficiaryDetails;
