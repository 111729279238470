import React, { FC, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import useReportsDetails from "./useReportsData";
import classes from "./style.module.scss";
import { FaChevronRight } from "react-icons/fa6";

const Reports: FC<{
  //  openStatus: boolean;
}> = ({}) => {
  const location = useLocation();
  const { activeTab, setActiveTab } = useReportsDetails({
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    if (location.pathname === "/reports/debit-credit-reports") {
      setActiveTab("creditDebitReports");
    } else if (location.pathname === "/reports/payment-receipts") {
      setActiveTab("paymentReports");
    } else if (location.pathname === "/reports/allTradeDetail-Reports") {
      setActiveTab("allTradeDetailReport");
    } else if (location.pathname === "/reports/ExcessPayment-Reports") {
      setActiveTab("excessPaymentReport");
    }
  }, [location]);
  return (
    <>
      <div className="p-4">
        <div className="flex gap-4">
          <div className="grow-[1] " style={{ width: "14%" }}>
            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "creditDebitReports"
                    ? classes.active
                    : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("creditDebitReports");
                }}
                to="/reports/debit-credit-reports"
              >
                Debits & Credits Reports<FaChevronRight />
              </Link>
            </div>
            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "paymentReports" ? classes.active : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("paymentReports");
                }}
                to="/reports/payment-receipts"
              >
                Trade Summary Report <FaChevronRight />
              </Link>
            </div>

            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "allTradeDetailReport"
                    ? classes.active
                    : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("allTradeDetailReport");
                }}
                to="/reports/allTradeDetail-Reports"
              >
                Mandi Reconciliation Report <FaChevronRight />
              </Link>
            </div>

            <div className="flex flex-col">
              <Link
                className={`py-2 px-4 cursor-pointer font-medium flex items-center justify-content-between br-10 mb-3 ${
                  activeTab === "excessPaymentReport"
                    ? classes.active
                    : classes.tab
                }`}
                onClick={() => {
                  setActiveTab("excessPaymentReport");
                }}
                to="/reports/ExcessPayment-Reports"
              >
                Mandi Reconciliation Report-2 <FaChevronRight />
              </Link>
            </div>
          </div>
          <div className="grow-[11] " style={{ width: "86%" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
